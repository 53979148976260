import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import { Slide } from "@material-ui/core";
import { Grid, TextField, Button } from "@material-ui/core";
import formatTitle from "../../components/common/formatTitle";
import objects from "../../../content/composants/contact_form_objects.json";
import randomKey from "../../helpers/randomKey";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFF",
    border: "2px solid #ccd4e6",
    fontSize: 16,
    padding: "18.5px 16px",
    "&:focus": {
      borderColor: "#110174",
    },
    "&:hover": {
      borderColor: "#110174",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    minHeight: "290px",
    marginBottom: theme.spacing(5),
  },
  formRegular: {
    backgroundColor: "#fcfcfc",
    padding: theme.spacing(6),
    marginTop: theme.spacing(4),
  },
  formRegularMobile: {
    backgroundColor: "#fcfcfc",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  label: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  select: {
    display: "block",
  },
  root: {
    backgroundColor: "transparent",
  },
  googleLink: {
    textDecoration: "none",
    color: "#110174",
  },
  noMargin: {
    margin: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  focused: {},
  notchedOutline: {},
}));

const ContactForm = ({ langKey, object, data }) => {
  const [values, setValues] = useState({
    email: "",
    fullname: "",
    object: objects[object] ? object : "",
    message: "",
    token: "",
  });
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();
  console.log(values);
  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha("contact");
    const datas = { ...values, token: result };

    const [firstname, ...lastName] = datas.fullname.split(" ");

    const fields = {
      email: datas.email,
      firstname,
      lastname: lastName.join(" "),
      message: `
      Objet : ${datas.object}
      ----------------------------------
      ${datas.message}
      `,
      token: result,
    };

    fetch(
      "https://lnayxtrjkj.execute-api.eu-west-1.amazonaws.com/prod-web/contact",
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          ...fields,
        }),
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
        return res.json();
      })
      .then((data) => {
        setSuccess(true);
      })
      .catch(() => {
        setSuccess(null);
      });
  };

  const validate = () =>
    typeof Object.keys(values)
      .filter((k) => k !== "token")
      .find((k) => values[k].length === 0) === "undefined";

  if (!data) return null;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.content}
      id="contact-form"
    >
      <Grid
        item
        className={clsx({
          [classes.formRegular]: matches,
          [classes.formRegularMobile]: !matches,
        })}
      >
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit}
          onChange={validate}
          alignItems="stretch"
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h1" className={classes.noMarginBottom}>
                {formatTitle(data.title)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.noMargin}
              >
                {data.required}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={4}>
              <Grid item sm={6} xs={12}>
                <label htmlFor="firstname" className={classes.label}>
                  {data.fullName}
                </label>
                <TextField
                  name="fullname"
                  id="fullname"
                  fullWidth
                  disabled={success}
                  inputProps={{
                    maxLength: 30,
                  }}
                  variant="outlined"
                  onChange={handleChange}
                  placeholder={data.fullNamePlaceholder}
                  className={classes.root}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <label htmlFor="email" className={classes.label}>
                  {data.email}
                </label>
                <TextField
                  name="email"
                  id="email"
                  fullWidth
                  disabled={success}
                  variant="outlined"
                  onChange={handleChange}
                  placeholder={data.emailPlaceholder}
                  type="email"
                  className={classes.root}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="email" className={classes.label}>
              {data.object}
            </label>
            <FormControl className={classes.select}>
              <NativeSelect
                name="object"
                value={values.object}
                disabled={success}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                input={<BootstrapInput />}
              >
                <option value="" />
                {Object.keys(data.objectItems).map((k) => (
                  <option key={`contact-${randomKey()}`} value={k}>
                    {data.objectItems[k]}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item>
            <label htmlFor="email" className={classes.label}>
              {data.message}
            </label>
            <TextField
              name="message"
              variant="outlined"
              fullWidth
              disabled={success}
              multiline
              rows={4}
              onChange={handleChange}
              placeholder={data.messagePlaceholder}
              className={classes.root}
            />
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ margin: 0 }}
            >
              {data.privacy[0]}
              <a
                href="https://policies.google.com/privacy"
                target="__blank"
                className={classes.googleLink}
              >
                {data.privacy[1]}
              </a>
              {data.privacy[2]}
              <a
                href="https://policies.google.com/terms"
                target="__blank"
                className={classes.googleLink}
              >
                {data.privacy[3]}
              </a>
              {data.privacy[4]}
              {data.privacy[5]}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={matches ? 0 : 2}
            >
              <Grid item sm={8} xs={12}>
                {success !== true && success !== null && (
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.noMargin}
                    style={{ fontSize: "11px", maxWidth: 450 }}
                  >
                    {data.rgpd}
                  </Typography>
                )}
                {success === null && (
                  <Slide in timeout={150}>
                    <div>{data.errorMessage}</div>
                  </Slide>
                )}
                {success === true && (
                  <Slide in timeout={150}>
                    <div
                      style={{
                        fontSize: "1.3em",
                        fontWeight: 600,
                        color: "#14142A",
                      }}
                    >
                      {data.successMessage}
                    </div>
                  </Slide>
                )}
              </Grid>
              <Grid item sm={3} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!validate() || success}
                  size="large"
                  color="primary"
                >
                  {data.btnLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContactForm.propTypes = {
  langKey: PropTypes.string.isRequired,
  object: PropTypes.string,
};

export default ContactForm;
